import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import axios from 'axios';
import Flickity from 'react-flickity-component';

class Header extends Component {

  // LoadGenres() {
  //   axios.get("https://www.bandlab.com/api/v2.0/contents/explore?types=genres", { "headers": { "x-client-id": "Angular" }})
  //     .then((result) => {
  //       this.setState({
  //         genres: result.data.data
  //       });
  //     });
  // }

  constructor(props){
    super(props);

    this.state = {
      genres: []
    };
  }

  componentDidMount() {

    // this.LoadGenres();

  }

  render() {
    return (
      <Flickity
        className={'carousel'} // default ''
        options={{
          cellAlign: 'left',
          initialIndex: 0,
          pageDots: false,
          prevNextButtons: false,
          contain: true,
          freeScroll: true }} // takes flickity options {}
        // reloadOnUpdate={true} // default false
      >
        <div className="carousel-cell">
          <Link to="/">
            <div className="carousel-title">All</div>
          </Link>
        </div>
        <div className="carousel-cell">
          <Link to="/hip-hop">
            <div className="carousel-title">Hip-Hop</div>
          </Link>
        </div>
        <div className="carousel-cell">
          <Link to="/pop">
            <div className="carousel-title">Pop</div>
          </Link>
        </div>
        <div className="carousel-cell">
          <Link to="/electronic">
            <div className="carousel-title">Electronic</div>
          </Link>
        </div>
        <div className="carousel-cell">
          <Link to="/rock">
            <div className="carousel-title">Rock</div>
          </Link>
        </div>
        <div className="carousel-cell">
          <Link to="/r-n-b">
            <div className="carousel-title">R&B</div>
          </Link>
        </div>
        <div className="carousel-cell">
          <Link to="/metal">
            <div className="carousel-title">Metal</div>
          </Link>
        </div>
        <div className="carousel-cell">
          <Link to="/jazz">
            <div className="carousel-title">Jazz</div>
          </Link>
        </div>
        <div className="carousel-cell">
          <Link to="/country">
            <div className="carousel-title">Country</div>
          </Link>
        </div>
        {/*{ this.state.genres.map((genre) => {*/}
          {/*return (*/}
            {/*<div className="carousel-cell">*/}
              {/*<Link to={`/${genre.id}`}>*/}
                {/*<div className="carousel-title">{genre.title}</div>*/}
              {/*</Link>*/}
            {/*</div>*/}
          {/*)})*/}
        {/*}*/}
      </Flickity>
    )
  }
}

export default Header;
