import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './home/views/Home';
import Header from './home/views/Header';
import Footer from './home/views/Footer';
import WhatsNew from './home/views/WhatsNew';
import FlipFeed from './home/views/FlipFeed';
import './App.scss';

class App extends Component {

  render() {
    return (
      <div className="body">
        <Header/>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/flipfeed' component={FlipFeed} />
          <Route exact path='/:genre' component={Home} />
          <Route exact path='/bandlab/whats-new' component={WhatsNew} />
          <Route exact path='/bandlab/whats-new/auth' component={FlipFeed} />
        </Switch>
        <Footer/>
      </div>
    );
  }
}

export default App;
