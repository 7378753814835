import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import axios from 'axios';
import moment from 'moment';

class Home extends Component {

  DecodeHtml(html) {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  LoadCategory(category) {
    axios.get(`https://lab.fm/wp-json/wp/v2/posts?per_page=20&categories=${category}`)
      .then((result) => {
        this.setState({
          articles: result.data,
          loading: false
        });
      });
  }

  LoadTag(tag) {
    axios.get(`https://lab.fm/wp-json/wp/v2/posts?per_page=20&tags=${tag}`)
      .then((result) => {
        this.setState({
          articles: result.data,
          loading: false
        });
      });
  }

  constructor(props) {
    super(props);

    this.state = {
      articles: [],
      loading: true,
      path: this.props.match.params.genre
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.state.path !== this.props.match.params.genre) {
          console.log(`Updated: ${this.state.path}`);
          console.log(`This Params: ${this.props.match.params.genre}`);

          this.setState({path: this.props.match.params.genre, loading: true},() => {
              switch (this.state.path) {
                  case 'country':
                      this.setState({ categories: 7, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadCategory(this.state.categories) });
                      break;
                  case 'hip-hop':
                      this.setState({ categories: 10, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadCategory(this.state.categories) });
                      break;
                  case 'electronic':
                      this.setState({ categories: 16, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadCategory(this.state.categories) });
                      break;
                  case 'jazz':
                      this.setState({ categories: 20, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadCategory(this.state.categories) });
                      break;
                  case 'metal':
                      this.setState({ categories: 18, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadCategory(this.state.categories) });
                      break;
                  case 'pop':
                      this.setState({ categories: 8, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadCategory(this.state.categories) });
                      break;
                  case 'r-n-b':
                      this.setState({ categories: 17, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadCategory(this.state.categories) });
                      break;
                  case 'rock':
                      this.setState({ categories: 9, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadCategory(this.state.categories) });
                      break;
                  case 'hot':
                    this.setState({ categories: 21, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadTag(this.state.categories) });
                    break;

                  default:
                      axios.get(`https://lab.fm/wp-json/wp/v2/posts?per_page=20`)
                          .then((result) => {
                              this.setState({
                                  brand: 'lab.fm',
                                  brandURL: 'https://lab.fm',
                                  articles: result.data,
                                  loading: false
                              });
                          });
              }

              });
      }
  }

    componentDidMount() {

    switch (this.state.path) {
      case 'country':
        this.setState({ categories: 7, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadCategory(this.state.categories) });
        break;
      case 'hip-hop':
        this.setState({ categories: 10, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadCategory(this.state.categories) });
        break;
      case 'electronic':
        this.setState({ categories: 16, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadCategory(this.state.categories) });
        break;
      case 'jazz':
        this.setState({ categories: 20, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadCategory(this.state.categories) });
        break;
      case 'metal':
        this.setState({ categories: 18, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadCategory(this.state.categories) });
        break;
      case 'pop':
        this.setState({ categories: 8, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadCategory(this.state.categories) });
        break;
      case 'r-n-b':
        this.setState({ categories: 17, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadCategory(this.state.categories) });
        break;
      case 'rock':
        this.setState({ categories: 9, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadCategory(this.state.categories) });
        break;
      case 'hot':
        this.setState({ categories: 21, brand: 'lab.fm', brandURL: 'https://lab.fm' }, () => { this.LoadTag(this.state.categories) });
        break;

      // START Some Extra Custom Feeds
      case 'guitar':
        this.setState({ brand: 'Guitar.com', brandURL: 'https://guitar.com' }, () => {
          axios.get(`https://guitar.com/wp-json/wp/v2/posts?per_page=20`)
            .then((result) => {
              this.setState({
                articles: result.data,
                loading: false
              });
            });
        });
        break;
      case 'guitar-magazine':
        this.setState({ brand: 'The Guitar Magazine', brandURL: 'https://www.theguitarmagazine.com' }, () => {
          axios.get(`https://www.theguitarmagazine.com/wp-json/wp/v2/posts?per_page=20`)
            .then((result) => {
              this.setState({
                articles: result.data,
                loading: false
              });
            });
        });
        break;
      case 'musictech':
        this.setState({ brand: 'MusicTech.net', brandURL: 'https://www.musictech.net' }, () => {
          axios.get(`https://www.musictech.net/wp-json/wp/v2/posts?per_page=20`)
            .then((result) => {
              this.setState({
                articles: result.data,
                loading: false
              });
            });
        });
        break;
      case 'rolling-stone':
        this.setState({ brand: 'Rolling Stone', brandURL: 'https://www.rollingstone.com' }, () => {
          axios.get(`https://www.rollingstone.com/wp-json/wp/v2/posts?per_page=20`)
            .then((result) => {
              this.setState({
                articles: result.data,
                loading: false
              });
            });
        });
        break;
      case 'nme':
        this.setState({ brand: 'NME', brandURL: 'https://www.nme.com' }, () => {
          axios.get(`https://www.nme.com/wp-json/wp/v2/posts?per_page=20`)
            .then((result) => {
              this.setState({
                articles: result.data,
                loading: false
              });
            });
        });
        break;
      // END Some Extra Custom Feeds

      default:
        axios.get(`https://lab.fm/wp-json/wp/v2/posts?per_page=20`)
          .then((result) => {
            this.setState({
              brand: 'lab.fm',
              brandURL: 'https://lab.fm',
              articles: result.data,
              loading: false
            });
          });
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="article-feed col-12">
            { (this.state.loading) ? (<ReactLoading className="react-loader mt-5" type="spin" color="rgba(126, 137, 153, .8)" /> ) : (this.state.articles.map((article) =>{
              return (
                <a className="article" key={article.id} href={article.link}>
                  <div className="article-card card text-left">
                    <div className="article-card-body card-body">
                      { (article.thumbnail_url) ? (
                        <div className="container pl-0 pr-0 mr-0 ml-0">
                          <div className="row">
                            <div className="article-image col-4 col-sm-3">
                              <img alt={this.DecodeHtml(article.title.rendered)} style={{ width: '100%' }} src={article.thumbnail_url}/>
                            </div>
                            <div className="col-8 col-sm-9 pl-0">
                              <h6 className="article-title">{this.DecodeHtml(article.title.rendered)}</h6>
                                <span className="text-muted article-meta-info">{ this.state.brand }</span><span className="text-muted article-meta-date"><span className="dot"></span>{ moment(article.date).fromNow() }</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="container mr-0 ml-0">
                          <div className="row">
                            <div>
                              <h6 className="article-title">{this.DecodeHtml(article.title.rendered)}</h6>
                                <span className="text-muted article-meta-info">{ this.state.brand }</span><span className="text-muted article-meta-date"><span className="dot"></span>{ moment(article.date).fromNow() }</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </a>
              )
            }))}
            { (this.state.loading) ? (<div/>) : (
              <a href={this.state.brandURL} style={{ color: 'black', textDecoration: "none", fontFamily: 'Roboto' }}>
                <div className="discover-more-button card text-left">
                  <div className="card-body">
                    <div className="container pl-0 pr-0 mr-0 ml-0">
                      <div className="row">
                        <div className="col-12">
                          <p className="mb-0 text-center">Discover More</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Home;
