import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {

  constructor(props){
    super(props);
    this.state = {
      tabs: [
        { fullName: "Hot", icon: "fa-fire", url: "/hot"},
        { fullName: "Latest", icon: "fa-clock", url: "/"}]
      };
    }

  render() {
    return (
      <div className="footer">
        {
          this.state.tabs.map((tab) => {
            return (
              <Link key={tab.icon} className="footer-tab" to={tab.url}>
                <span><i className={`fas ${tab.icon}`}/>&nbsp;&nbsp;{tab.fullName}</span>
              </Link>
            )
            })
        }
      </div>
    )
  }
}

export default Footer;
