import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import axios from 'axios';
// import moment from 'moment';
import FlipPage from 'react-flip-page';

class FlipFeed extends Component {

  DecodeHtml(html) {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  LoadCategory(category) {
    axios.get(`https://lab.fm/wp-json/wp/v2/posts?per_page=20&categories=${category}`)
      .then((result) => {
        this.setState({
          articles: result.data,
          loading: false
        });
      });
  }

  LoadTag(tag) {
    axios.get(`https://lab.fm/wp-json/wp/v2/posts?per_page=20&tags=${tag}`)
      .then((result) => {
        this.setState({
          articles: result.data,
          loading: false
        });
      });
  }

  constructor(props) {
    super(props);

    this.state = {
      articles: [],
      loading: true,
      path: this.props.match.params.genre
    };
  }

  componentDidMount() {

    window.scrollTo(0,1);

    let header = document.getElementsByClassName('carousel')
    if (header[0]) {
      header[0].style.display = 'none';
    }

    // let footer = document.getElementsByClassName('footer')
    // if (footer[0]) {
    //   footer[0].style.display = 'none';
    // }

    document.body.setAttribute('style', 'margin:0 !important');

    switch (this.state.path) {
      default:
        axios.get(`https://lab.fm/wp-json/wp/v2/posts?per_page=10&tags=21`)
          .then((result) => {
            this.setState({
              brand: 'lab.fm',
              brandURL: 'https://lab.fm',
              articles: result.data,
              loading: false
            });
            console.log(this.state.articles[0]);
          });
    }
  }

  render() {
    if (this.state.loading) {
      return (<ReactLoading className="react-loader mt-5" type="spin" color="rgba(126, 137, 153, .8)" />);
    }
    return (
      <FlipPage orientation="horizontal" responsive="true" width="100%" className="dailySummary" showHint="true">
        <article>
          <div className='article-image' style={{ backgroundImage: `url('${this.state.articles[0].thumbnail_url}')` }}>
            <div className="article-overlay" />
            <h1>{this.state.articles[0].title.rendered}</h1>
            <h2>lab.fm</h2>
          </div>
          <div className="article-split">
            <div className='article-image' style={{ backgroundImage: `url('${this.state.articles[1].thumbnail_url}')` }} />
            <h1>{this.state.articles[1].title.rendered}</h1>
            <h3>lab.fm</h3>
          </div>
          <div className="article-split">
            <div className='article-image' style={{ backgroundImage: `url('${this.state.articles[2].thumbnail_url}')` }} />
            <h1>{this.state.articles[2].title.rendered}</h1>
            <h3>lab.fm</h3>
          </div>
        </article>
        <article>
          <div className='article-image' style={{ backgroundImage: `url('${this.state.articles[3].thumbnail_url}')` }}>
            <div className="article-overlay" />
          </div>
          <h1>{this.state.articles[3].title.rendered}</h1>
          <h2>lab.fm</h2>
          <div className="article-split">
            <div className='article-image' style={{ backgroundImage: `url('${this.state.articles[4].thumbnail_url}')` }} />
            <h1>{this.state.articles[4].title.rendered}</h1>
            <h3>lab.fm</h3>
          </div>
          <div className="article-split">
            <div className='article-image' style={{ backgroundImage: `url('${this.state.articles[5].thumbnail_url}')` }} />
            <h1>{this.state.articles[5].title.rendered}</h1>
            <h3>lab.fm</h3>
          </div>
        </article>
        <article>
          <div className='article-image' style={{ backgroundImage: `url('${this.state.articles[6].thumbnail_url}')` }}>
            <div className="article-overlay" />
          </div>
          <h1>{this.state.articles[6].title.rendered}</h1>
          <h2>lab.fm</h2>
          <div className="article-split col-6">
            <div className='article-image' style={{ backgroundImage: `url('${this.state.articles[7].thumbnail_url}')` }} />
            <h1>{this.state.articles[7].title.rendered}</h1>
            <h3>lab.fm</h3>
          </div>
          <div className="article-split col-6">
            <div className='article-image' style={{ backgroundImage: `url('${this.state.articles[8].thumbnail_url}')` }} />
            <h1>{this.state.articles[8].title.rendered}</h1>
            <h3>lab.fm</h3>
          </div>
        </article>
      </FlipPage>
    )
  }
}

export default FlipFeed;
