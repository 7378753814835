import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';
import { Loader } from '../../common/components/Global';
import Flickity from 'react-flickity-component';

class WhatsNew extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: '',
      date: '',
      updateDate: '',
      copy: '',
    };
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    if (parsed.key) {
      const headers = {
        "x-client-id": "BandLab-Web",
      };
      const data = {
        "key": parsed.key,
        "provider": "key",
      };
      axios({
        method: 'POST',
        url: 'https://test.bandlab.com/api/v1.3/authorizations',
        data,
        headers,
      })
        .then(res => axios({
          method: 'GET',
          url: 'https://test.bandlab.com/api/v1.3/me',
          headers: {
            "Authorization": `Bearer ${res.data.sessionKey}`,
            "x-client-id": "BandLab-Web",
          },
        }))
        .then(response => this.setState({
          name: ` for ${response.data.name}`,
        }));
    }

    const headers = {
      Authorization: "Bearer undefined",
    };

    Promise.all([
      axios({
        method: 'GET',
        url: 'https://cosmos-api-dot-blt-cosmos.appspot.com/api/updates',
        headers,
      }),
      axios({
        method: 'GET',
        url: 'https://cosmos-api-dot-blt-cosmos.appspot.com/api/featuredupdates',
        headers,
      }),
    ]).then(res => this.setState({
      loading: false,
      updates: res[0].data,
      featuredUpdates: res[1].data,
    }))

    let footer = document.getElementsByClassName('footer')
    if (footer[0]) {
      footer[0].style.display = 'none';
    }
    let header = document.getElementsByClassName('carousel')
    if (header[0]) {
      header[0].style.display = 'none';
    }
  }

  reformat = (copy) => {
    let blah = copy;
    blah = blah.replace(/<copy>/g, '<p>•&nbsp;&nbsp;');
    blah = blah.replace(/<\/copy>/g, '</p>');
    blah = blah.replace(/<platform>/g, '<div class="platform-block">');
    blah = blah.replace(/<\/platform>/g, '</div>');
    blah = blah.replace(/<type>/g, '<span>');
    blah = blah.replace(/<\/type>/g, '</span><div class="break"></div>');
    return blah;
  }

  parseTag = (copy, tag) => {
    return copy.split(`<${tag}>`).pop().split(`</${tag}>`)[0];
  }

  reformatPlatforms = (copy) => {
    let blah = copy;
    blah = blah.replace(/<type>/g, '<span>');
    blah = blah.replace(/<\/type>/g, '</span><div class="break"></div>');
    return blah;
  }

  render() {
    const { loading } = this.state;
    if (loading) { return <Loader />; }
    return (
      <div className="container-fluid">
        <div className="row whats-new-carousel">
          <div className="col-12 pl-0 pr-0">
            <h1>Hot Releases{this.state.name}</h1>
            <Flickity
              className={'carousel'} // default ''
              options={{
                cellAlign: 'center',
                initialIndex: 0,
                pageDots: false,
                prevNextButtons: false,
                contain: true,
                freeScroll: false,
                wrapAround: true,
              }}
            >
              {this.state.featuredUpdates ? this.state.featuredUpdates.map(featured => (
                <div key={featured.id} className="carousel-cell" style={{ backgroundImage: `url('${featured.image}')` }}>
                  <a style={{ cursor: 'pointer' }} href={featured.link} target="_self" rel="noreferrer">
                    <div className="carousel-title">{this.parseTag(featured.features, 'copy')}</div>
                  </a>
                  <div className="platform-overlay" />
                  <div className="platform-block" dangerouslySetInnerHTML={{ __html: this.reformatPlatforms(this.parseTag(featured.features, 'platform')) }} />
                </div>
              )) : ''}
            </Flickity>
          </div>
        </div >
        <div className="row whats-new">
          <div className="col-12">
            <h1>All Releases</h1>
            {this.state.updates ? this.state.updates.map(update => (
              <div key={update.id} >
                <h3>{moment(update.updateDate).format('MMMM D, YYYY')}</h3>
                <h2>🎉 Features</h2>
                <div dangerouslySetInnerHTML={{ __html: this.reformat(update.features) }} />
              </div>
            )) : ''}
          </div>
        </div>
      </div >
    )
  }
}

export default WhatsNew;
